

.login-container{
	width: 100%;
	background-color: rgb(248,252,252); /*button: rgb(37, 150, 190) grey: rgb(248,252,252) block: rgb(16,28,44) grey-text: rgba(111,120,134,255)*/
	display: flex;
	flex-direction: column;
	align-items: center;
}



.login-logo{
	font-size: 40px;
	font-weight: 900;
	flex-grow: 1;
}

.login-navbar{
	width: 60%;
	display: flex;
}



.login-button{
	cursor: pointer;
	margin: 10px;
	font-size: 25px;
}

.lb1{
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 7px;
	background-color: white;
	border-radius: 25px;
	color: black;
	width: 100px;
	text-align: center;
}

.login-header{
	width: 100%;
	height: 100vh;
	background-color: rgb(248,252,252);
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
}

.login-header-image{
	width: 440px;
	height: 720px;
}

.logos{
	width: 500px;
}

.main-logo{
	width: 50px;
}

.login-description{
	width: 50%;

	text-align: center;
}

.descr-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.ld1{
	color: rgb(24,20,20);
	margin-top: 10%;
	font-size: 65px;
	font-weight: bold;
}

.ld2{
	
    line-height: 1.5;
	font-size: 35px;
	color: rgba(111,120,134,255);
}

.login-info-buttons{
	display: flex;
	justify-content: center;
}

.login-info-button{
	margin: 20px;
	font-size: 25px;
	width: 200px;
	border-radius: 10px;
	cursor: pointer;
	padding-top: 15px;
	padding-bottom: 15px;
}

.lib1{
	background-color: rgb(4,132,228);
	color: white;
	
}

.lib2{
	background-color: rgb(24,20,20);
	color: white;
}


.login-line{
	margin-top: 30px;
	margin-bottom: 30px;
	width: 80rem;
	height: 1px;
	background-color: rgb(16,28,44);
}

.login-main{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: center;
	background-color: rgb(248,252,252);
}

.faq-main-block{
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
}

.main-h{
	font-size: 45px;
	margin-top: 50px;
	color: rgb(24,20,20);
	font-weight: bold;
}

.first-login-block{
	width: 70vw;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;

}

.login-block{
	width: 70vw;
	margin-top: 120px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.login-block-no-image{
	width: 70vw;
	margin-top: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.login-h{
	font-size: 45px;
	color: rgb(24,20,20);
	display: flex;
	align-items: center;
	flex-direction: column;
	font-weight: bold;
}



.login-img{
	border-radius: 25px;
}

.login-main-descr{
	width: 50rem;
	font-size: 25px;
	margin-top: 20px;
	width: 30rem;
	font-weight: normal;
	color: rgba(111,120,134,255);
}



.login-sell{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
}

.login-offer{
	margin-top: 30px;
	
	
}
.offer-main{
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;

	border: 2px solid rgba(12,24,46,255);
	padding: 15px;
	display: flex;
	width: 350px;
	height: 650px;
	flex-direction: column;
	align-items: center;
}
.login-block{
 /*border-bottom: 1px solid #d44313;*/
}

.buy-button{
	text-align: center;
	cursor: pointer;
	padding: 5px;
	font-size: 25px;
	border: 1px solid black;
	width: 200px;
	border-radius: 25px;
	background-color:  rgba(12,24,46,255);
	color: white;
}

.buy-button:hover{
	background-color: white;
	color: black;
}

.login-footer{
	background-color: black;
	height: 50px;
	padding-top: 10px;
	font-size: 22px;
	color: white;
	width: 100%;
}

.login-email{
	border-radius: 25px;
	padding: 5px;
	font-size: 20px;
	width: 280px;
}

/*FORMS*/



.login-form-container, .signup-form-container{
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
}

.login-form, .signup-form{
	color: black;
	background-color: #300073;
	width: 500px;
	border-radius: 25px;
	height: 300px;
	position: absolute;
	z-index: 1000000;
	left: 50%;
   top: 50%;
   transform: translate(-50%,-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}


.login-email{
	padding: 10px;
	margin:20px;
}

.submit-login-button{
	width: 200px;
	text-align: center;
	background-color: white;
	padding: 5px;
	font-size: 25px;
	border-radius: 25px;
	cursor: pointer;
}


.signup-form{
	height: 500px;
}

.login-close-form{
	color: white;
	font-size: 25px;
	margin-top: -30px;
	margin-left: 430px;
	cursor: pointer;
}

.email-password-not-match{
	font-size: 15px;
	color: #ff6666;
	margin-top: -10px;
	margin-bottom: 10px;
}

.login-lg-buttons{
	display: flex;
}



.login-double-block{
	display: flex;
	flex-direction: row;
}

.login-img{
	margin-top: 10px;
}

.faq-button{
	font-size: 25px;
}
.faq-button:hover{
	cursor: pointer;
}

.login-features-list{
	font-size: 20px;
	line-height: 1.2;
}

.rotate90{
	transform: rotate(90deg);
}

.faq-block{
	margin: 10px;
	margin-top: 50px;

	text-align: left;
	display: flex;
	flex-direction: row;
}

.showAns{
	font-size: 20px;
	line-height: 1.5;
	font-size: 20px;
	width: 35vw;
	margin-left: 30px;
}

.faq-head{
	font-weight: bold;
	width: 15vw;
	font-size: 25px;
	line-height: 1.5;
}

.preview{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
}

.preview > video{
	margin-top: 10px;
	width: 1000px;
}

.four-login-blocks{
	display: flex;
	flex-direction: column;
}

.template-h{
	font-size: 18px;
	cursor: pointer;
	color: #d15b4b;
	font-weight: bold;
}

.template-textarea{
	width: 400px;
	resize: none;
	border-radius: 25px;
	padding: 10px;
	 word-wrap: break-word;

}

.main-blocks-column{
	display: flex;
	flex-direction: column;

}

.four-login-blocks:nth-child(odd) {
	margin-right: 20px;
}

.four-login-blocks:nth-child(even) {
	margin-left: 20px;
}

.template-example{
	margin-top: -10px;
}



.comments-example{
	margin-top: 20px;
	max-width: 320px !important;
	height: 510px;
	
}

.login-features-list > span{
	font-size: 25px;	
	color: rgba(12,24,46,255);
	font-weight: bold;
}

.promo-icon{
		margin-right: 10px;
	}


.promo-img3, .promo-img5, .comments-example{
	box-shadow: -10px 10px 10px grey;
  	width: 500px !important;
 }
.promo-img3{
	width: 450px !important;
}

.promo-img1, .promo-img2, .promo-img4{
	box-shadow: 10px 10px 5px grey;
  	width: 500px !important;
}

.promo-heading{
	width: 30rem;
}

.login-h:nth-child(odd){
	margin-right: 80px;
}
.login-h:nth-child(even){
	margin-left: 80px;
}

.first-promo{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(12,24,46,255);
	padding-bottom: 40px;
	padding-top: 50px;
}

.fp-item-three{
	display: flex;
	flex-direction: row;
}

.fp-item{
	color: #e7e3e3;
	display: flex;
	flex-direction: column;
	margin: 30px;
	width: 200px;
	text-align: center;
	font-size: 25px;
}

.fp-icon{
	font-size: 50px;
	margin-bottom: 5px;
	color: #bcd2e5;
}

.fp-h{
	font-size: 50px;
	color: #e7e3e3;
}

.fp-line{
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
	height: 1px;
	background-color: #e7e3e3;
}

.fp-line-mobile{
	display: none;
}

.image-container{
	position: relative;
	z-index: 3;

}
.promo-img1, .promo-img2, .promo-img3, .promo-img4, .promo-img5, .comments-example{
	z-index: 2;
}

.dotted-grid{
	position: absolute;
	width: 160px;
	height: 140px;
  	background-image: radial-gradient(grey 1px, transparent 0);
  	background-size: 6px 6px;
}

.dotted-grid-right{
	z-index: -1;
	left: 75%;
	top: -30px;
}

.dotted-grid-left{
	z-index: -1;
	right: 75%;
	top: -30px;
}

.dotted-grid-left-comment{
	z-index: -1;
	right: 62%;
	top: -10px;
}

.promo-img-mobile{
	display: none;
}
.preview-logo{
	width: 90px;
}  
@media only screen and (max-width: 850px) {
	.image-container{
		display: none;
	}
	.promo-img-mobile{
		display: block;
		width: 80vw! important;
	}
	.preview-logo{
		width: 50px;
	}  
	.login-block{
		margin-top: 50px;
	}

	.login-header{
		height: 480px;
	}  
	.login-description{
		margin-top: 10px;
	}

	.promo-img1, .promo-img2, .promo-img3, .promo-img4, .promo-img5, .comments-example{
		width: 95vw !important;
	}
	
	.comments-example{
		height: 50vh;
	}
	.login-header-image{
		display: none;
	}
	.promo-heading{
		font-size: 18px !important;
		width: 90vw !important;
	}
	.login-h{
		margin: 0 !important;
		width: 90vw !important;
	}
	.promo-icon{
		font-size: 25px !important;
	}
	.login-main-descr{
		font-size: 17px;
		width: 90vw;
	}
	.login-line{
		width: 90vw;
	}
	.preview-video{
		width: 90vw !important;
	}
	.faq-block{
		width: 90vw;
		flex-direction: column;
		justify-content: center;
	}
	.faq-head{
		width: 90vw;
		text-align: center;
		font-size: 20px !important;
	}
	.showAns{
		width: 90vw;
		text-align: center;
		font-size: 15px;
		margin: 0;
	}
	.ld1{
		font-size: 35px;
	}
	.ld2{
		font-size: 20px;
	}
	.login-description{
		width: 90vw;
	}
	.lib1, .lib2{
		font-size: 20px;
		height: 25px;
	}
	.offer-heading{
		width: 346px !important;
		font-size: 30px !important;
	}
	.offer-main{
		width: 312px!important;
		height: 620px;
	}
	.price-of-pack{
		font-size: 30px !important;
	}
	.login-features-list{
		font-size: 18px;
	}
	.buy-button{
		font-size: 20px;
	}
	.fp-h{
		text-align: center;
		font-size: 20px;
	}
	.fp-icon{
		font-size: 35px;
	}
	.fp-item{
		margin: 0;
		font-size: 18px;
	}
	.fp-line{
		display: none;
	}
	.fp-line-mobile{
		display: block;
		margin-top: 30px;
		margin-bottom: 30px;
		width: 100%;
		height: 1px;
		background-color: #e7e3e3;
	}
	.fp-item-three{
		flex-direction: column;
	}
	.main-h{
		font-size: 30px;
	}
	.first-login-block, .login-block{
		flex-direction: column;
		align-items: center;
	}
	.dotted-grid{
		display: none;
	}
	.login-footer{
		background-color: black;
		height: 150px;
		padding-top: 10px;
		font-size: 22px;
		color: white;
		width: 100%;
	}
	.plus-vat{
		font-size: 10px !important;
	}
}

@media only screen and (min-width: 550px) and (max-width: 850px) {
	.comments-example{
		width: 70vw !important;
		height: 500px !important;
	}
}
